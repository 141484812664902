.header-projectName {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.mobileMenu {
  @apply block lg:hidden;
}

.mobileMenu-bg {
  @apply w-screen h-screen fixed left-0 bg-black/80  transition-all;
  transform: translate(-100%, 0);
}

.mobileMenu-content {
  @apply w-full min-w-[200px] max-w-[280px] h-full shadow bg-white py-4 lg:p-0;
}

.mobileMenu-toggle {
  @apply block relative w-[20px] h-[15px] z-10 select-none p-0 border-0 bg-inherit;
}

.mobileMenu-toggle span {
  @apply block absolute w-full h-[2px] bg-black rounded-sm;
  transition: opacity 0s 0.25s, top 0.25s 0.25s, bottom 0.25s 0.25s,
    transform 0.25s;
}

.mobileMenu-toggle span:nth-child(1) {
  @apply top-0;
}
.mobileMenu-toggle span:nth-child(3) {
  @apply bottom-0;
}
.mobileMenu-toggle span:nth-child(2) {
  top: 50%;
  transform: translate(0, -50%);
}

.mobileMenu-toggle.open span:nth-child(1) {
  top: 50%;
  transition: top 0.25s, transform 0.25s 0.25s;
  transform: translate(0, -50%) rotate(-45deg);
}
.mobileMenu-toggle.open span:nth-child(3) {
  bottom: 50%;
  transition: bottom 0.25s, transform 0.25s 0.25s;
  transform: translate(0, 50%) rotate(45deg);
}

.mobileMenu-toggle.open span:nth-child(2) {
  @apply opacity-0;
  transition: opacity 0s;
}
